import { styled } from '@fis/util-theme';

const StyledResponsiveFlexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$3',
  width: '100%',
  minWidth: '0',

  variants: {
    flexColumn: {
      true: {
        flexDirection: 'column',
      },
    },
    flexGap: {
      4: { gap: '$2' },
      8: { gap: '$3' },
      16: { gap: '$5' },
      24: { gap: '$7' },
      32: { gap: '$10' },
    },
    align: {
      start: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'end',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    justify: {
      'space-between': {
        justifyContent: 'space-between',
      },
      start: {
        justifyContent: 'start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'end',
      },
    },
    flexWrap: {
      true: {
        flexWrap: 'wrap',
      },
    },
    contentWidth: {
      true: {
        width: 'max-content',
        minWidth: 'min-content',
      },
    },
  },
});

export const ResponsiveFlexContainer = (props: {
  children: any;
  flexColumn?: boolean; // defaults to False
  flexGap?: 4 | 8 | 16 | 24 | 32; // defaults to 8
  align?: 'start' | 'center' | 'end' | 'baseline';
  flexWrap?: boolean; // defaults to false
  contentWidth?: boolean; // defaults to false
  justify?: 'space-between' | 'start' | 'center' | 'end';
}) => {
  const { children, flexColumn, flexGap, align, contentWidth, justify } = props;

  return (
    <StyledResponsiveFlexContainer
      flexColumn={flexColumn ? flexColumn : false}
      flexGap={flexGap ? flexGap : 8}
      align={align ? align : 'start'}
      contentWidth={contentWidth === true ? true : false}
      justify={justify ? justify : 'start'}
    >
      {children}
    </StyledResponsiveFlexContainer>
  );
};
