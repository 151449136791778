import React from 'react';
import CSSType from 'csstype';

import { styled } from '@fis/util-theme';
import { Heading, Level } from '@fis/ui-heading';

/* -------------------------------------------------------------------------------------------------
 * SectionTitle
 * -----------------------------------------------------------------------------------------------*/

const SectionTitle = styled(Heading, {
  fontFamily: '$firaCode',
  fontSize: '$80',
  fontWeight: '$bold',
  lineHeight: '$8', // 1.235
  letterSpacing: '$1',
  marginBottom: '$10',
});

SectionTitle.displayName = 'SectionTitle';

/* -------------------------------------------------------------------------------------------------
 * SectionContent
 * -----------------------------------------------------------------------------------------------*/

const StyledSectionContent = styled('div', {
  width: '100%',
});

type SectionContentProps = React.ComponentPropsWithRef<
  typeof StyledSectionContent
> & {
  minHeight?: CSSType.Property.MinHeight;
  loading?: boolean;
};

const SectionContent = React.forwardRef<HTMLDivElement, SectionContentProps>(
  ({ children, loading = false, minHeight = 0, ...rest }, ref) => {
    return (
      <StyledSectionContent
        css={{ minHeight }}
        {...rest}
        aria-busy={loading}
        ref={ref}
      >
        {children}
      </StyledSectionContent>
    );
  }
);

SectionContent.toString = () => '.section-content';
SectionContent.displayName = 'SectionContent';

/* -------------------------------------------------------------------------------------------------
 * Section
 * -----------------------------------------------------------------------------------------------*/

const SectionTitleType = (<SectionTitle />).type;

const StyledSection = styled('section', {
  width: '100%',
});

type Props = React.ComponentPropsWithRef<typeof StyledSection>;

const Section = React.forwardRef<HTMLElement, Props>(
  ({ children, ...rest }, ref) => {
    const hasTitle = React.Children.toArray(children).find(
      (child) => (child as React.ReactElement).type === SectionTitleType
    );

    return hasTitle ? (
      <Level>
        <StyledSection {...rest} ref={ref}>
          {children}
        </StyledSection>
      </Level>
    ) : (
      <StyledSection {...rest} ref={ref}>
        {children}
      </StyledSection>
    );
  }
);

Section.toString = () => '.section';
Section.displayName = 'Section';

const Root = Section;
const Title = SectionTitle;
const Content = SectionContent;

export {
  Section,
  SectionTitle,
  SectionContent,
  //
  Root,
  Title,
  Content,
};
